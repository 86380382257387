<template>
  <div class="preview-layer" v-if="visible" @click="closePreviewModal">
    <div class="preview-view">
      <RealBrakeMachinePreview @click.stop>
        <template v-slot:preview>
          <div class="box_body">
            <img
              src="../../assets/tongxingma.png"
              class="bj_tongxingma"
              alt=""
            />
          </div>
          <slot name="announcementContent"></slot>
        </template>
      </RealBrakeMachinePreview>
      <slot name="btnGroup"></slot>
    </div>
  </div>
</template>

<script>
import RealBrakeMachinePreview from "@/components/RealBrakeMachinePreview";

const MEETING_ROOM_TYPE = {
  PUBLIC: 2,
  PRIVATE: 1,
};
export default {
  name: "AnnouncementPreview",
  components: {
    RealBrakeMachinePreview,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    company: String,
    Announcement: Object,
  },
  data() {
    return {
      MEETING_ROOM_TYPE,
    };
  },
  methods: {
    // 关闭弹窗
    closePreviewModal() {
      this.$emit("closeModal");
    },
  },
  mounted() {},
};
</script>
<style scoped>
.box_body {
  background-color: #fff;
}
.bj_tongxingma {
  width: 100%;
  height: 274px !important;
}
.box_content {
  height: 264px;
}


</style>
